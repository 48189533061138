<template>
	<div class="clinic-list clinic-table">
		<CommonTable
			:total-items="totalItems"
			:total-pages="totalPages"
			:recent-filter="recentFilters"
			:show-actions="isCurrentSystemAdmin"
			:type="tableType"
			@create="goToCreateForm"
			@search="search"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headers"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
						>{{ header.title }}</th
					>
				</tr>
			</template>
			<template #body>
				<template v-if="clinics && clinics.length">
					<tr
						v-for="(clinic, index2) in clinics"
						:key="`${clinic.id}-${index2}`"
						@click="goToClinic(clinic.id)"
						@click.right="goToClinicNewTab(clinic.id)"
						@click.middle="goToClinicNewTab(clinic.id)"
					>
						<td scope="row" class="clinic-table__code">{{ clinic.code }}</td>
						<td scope="row" class="text-break text-pre-wrap clinic-table__name"
							>{{ clinic.name }}
							<star-rating
								v-model="clinic.rating"
								:increment="0.01"
								:star-size="15"
								:show-rating="false"
								read-only
								active-color="rgb(255, 193, 32)"
							></star-rating
						></td>
						<td scope="row" class="text-break text-pre-wrap">{{ labelType(clinic.type) }}</td>
						<td scope="row" class="text-break text-pre-wrap">{{ clinic.city.name }}</td>
						<td scope="row" class="text-break text-pre-wrap clinic-table__address">{{
							clinic.address
						}}</td>
						<td scope="row" class="text-break text-pre-wrap clinic-table__phone">{{
							clinic.phone
						}}</td>
						<td scope="row" :class="`text-break text-pre-wrap ${getStatusClass(clinic.status)}`">{{
							labelStatus(clinic.status)
						}}</td>
						<td class="text-center"
							><router-link
								:to="{ name: 'UpdateClinic', params: { id: clinic.id } }"
								class="link-icon text-info"
							>
								<span class="prefix-input d-inline-block">
									<font-awesome-icon :icon="['fas', 'eye']" :title="$t('Action.Edit')" />
								</span>
							</router-link>
						</td>
					</tr>
				</template>
				<template v-else>
					<tr class="text-center">
						<td colspan="12" class="fit">{{ $t("Table.NoData") }}</td>
					</tr>
				</template>
			</template>
		</CommonTable>
	</div>
</template>

<script>
import { CLINIC_TYPE, CLINIC_STATUS, USER_ROLE, MANAGEMENT } from "@/shared/plugins/constants"
import { GET_CLINICS } from "./../store/action-types"
import { RESET_FILTERS_CLINIC_LIST } from "./../store/mutation-types"
import { createNamespacedHelpers, mapState } from "vuex"
const { mapActions, mapMutations } = createNamespacedHelpers("clinics")

export default {
	name: "ClinicList",

	components: {},

	props: {},

	data() {
		return {
			tableType: MANAGEMENT.CLINIC,

			headers: [
				{
					title: this.$t("ClinicForm.PKCode"),
					class: "fit clinic-table__code",
				},
				{
					title: this.$t("ClinicForm.Name"),
					class: "text-nowrap clinic-table__name",
				},
				{
					title: this.$t("ClinicForm.Type"),
					class: "text-nowrap clinic-table__type",
				},
				{
					title: this.$t("ClinicForm.Province"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ClinicForm.Address"),
					class: "text-nowrap clinic-table__address",
				},
				{
					title: this.$t("ClinicForm.Phone"),
					class: "text-nowrap clinic-table__phone",
				},
				{
					title: this.$t("ClinicForm.Status"),
					class: "text-nowrap clinic-table__status",
				},
				{
					title: this.$t("Action.ViewEdit"),
					class: "text-nowrap",
				},
			],
		}
	},

	computed: {
		...mapState("clinics", ["clinics", "totalItems", "totalPages", "recentFilters"]),
		...mapState("authentication", ["currentUser"]),

		isCurrentSystemAdmin: function () {
			return this.currentUser.role === USER_ROLE.SYSTEM_ADMIN
		},
	},

	watch: {},

	created() {},

	beforeDestroy() {
		if (!this.$route.path.includes("clinics")) this.RESET_FILTERS_CLINIC_LIST()
	},

	methods: {
		...mapActions({ GET_CLINICS }),
		...mapMutations({ RESET_FILTERS_CLINIC_LIST }),

		search(params) {
			this.GET_CLINICS(params)
		},

		goToCreateForm() {
			this.$router.push({ name: "CreateClinic" })
		},

		goToClinic(clinicId) {
			this.$router.push({ name: "UpdateClinic", params: { id: clinicId } })
		},

		goToClinicNewTab(clinicId) {
			let routeData = this.$router.resolve({
				name: "UpdateClinic",
				params: { id: clinicId },
			})
			window.open(routeData.href, "_blank")
		},

		labelType(value) {
			return {
				[CLINIC_TYPE.TU_NHAN]: this.$t("ClinicForm.PrivateClinic"),
				[CLINIC_TYPE.CONG]: this.$t("ClinicForm.PublicClinic"),
				[CLINIC_TYPE.DNXH]: this.$t("ClinicForm.DNXHClinic"),
				[CLINIC_TYPE.CBO]: this.$t("ClinicForm.CBOClinic"),
			}[value]
		},

		labelStatus(value) {
			return {
				[CLINIC_STATUS.ACTIVE]: this.$t("Status.Active"),
				[CLINIC_STATUS.INACTIVE]: this.$t("Status.Inactive"),
			}[value]
		},

		getStatusClass(status) {
			return {
				[CLINIC_STATUS.ACTIVE]: "status--active",
				[CLINIC_STATUS.INACTIVE]: "status--inactive",
			}[status]
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.clinic-list {
	.link-permission {
		text-decoration: underline;
	}

	/deep/ .vue-star-rating {
		padding-top: 1px;

		.vue-star-rating-star {
			display: block;
		}
	}

	tbody tr:hover {
		cursor: pointer;
	}
}

.clinic-table {
	&__code {
		min-width: 60px;
		max-width: 80px;
	}
	&__name {
		min-width: 125px;
		max-width: 150px;
	}
	&__type {
		min-width: 94px;
	}
	&__phone {
		min-width: 100px;
		max-width: 120px;
	}
	&__address {
		min-width: 160px;
		max-width: 250px;
	}
	&__website {
		min-width: 120px;
		max-width: 240px;
	}
	&__status {
		min-width: 92px;
	}

	&__code,
	&__phone,
	&__address,
	&__website {
		white-space: nowrap !important;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.status {
	&--active {
		color: $color-sea-green;
	}
	&--inactive {
		color: $color-red;
	}
}
</style>
